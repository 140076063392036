/* eslint-disable */
export function getCookie(name: string) {
  const matches = document.cookie.match(
    new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

// interface ICookieDate extends Date{
//   toUTCString: () => string,
// }

type TProps = {
  path?: string,
  expires?: Date | string | number ,
  [propName: string] : any,
}

export function setCookie(name: string, value: any, props: TProps) {
  props = props || {};
  let exp = props.expires;
  if (typeof exp === 'number' && exp) {
    const d = new Date();
    d.setTime(d.getTime() + exp * 1000);
    exp = props.expires = d;
  }
  // @ts-ignore
  if (exp && exp.toUTCString) {
    // @ts-ignore
    props.expires = exp.toUTCString();
  }
  value = encodeURIComponent(value);
  let updatedCookie = `${name}=${value}`;
  for (const propName in props) {
    updatedCookie += `; ${propName}`;
    const propValue = props[propName];
    if (propValue !== true) {
      updatedCookie += `=${propValue}`;
    }
  }
  document.cookie = updatedCookie;
}

export function deleteCookie(name: string) {
  setCookie(name, null, { expires: -1 });
}
